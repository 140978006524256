import React, { useEffect, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import useWebsiteAPI from '../../Api/websiteAPI';
import HeaderWithActions from '../TileLayout/HeaderWithActions';
import FormInput from '../ReusableElements/FormInput';
import BasicButton from '../ReusableElements/basicButton';

const EmployeePortalCustomForm = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  const inputFields = [
    {
      'Input Name': 'customerNum',
      'Input Type': 'text',
      'Placeholder Text': 'Customer Account Number',
      Required: 'false',
    },
    {
      'Input Name': 'itemNum',
      'Input Type': 'text',
      'Placeholder Text': 'Item Number',
      Required: 'false',
    },
    {
      'Input Name': 'files',
      'Input Type': 'files',
      'Placeholder Text': 'Upload a File',
      Required: 'false',
    },
    {
      'Input Name': 'additionalInfo',
      'Input Type': 'textarea',
      'Placeholder Text': 'Additional Information',
      Required: 'true',
    },
  ];

  const check =
    'https://res.cloudinary.com/da3rom333/image/upload/v1688653577/Website%20Assets/SVGs/green_check_d3olgm.svg';
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({ files: [] });
  const [submitComplete, setSubmitComplete] = useState(false);
  const [continueValid, setContinueValid] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState();
  const [notification, setNotification] = useState();
  // const claimFormStyle = {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   position: 'absolute',
  //   left: '23rem',
  //   top: '17rem',
  //   cursor: 'pointer',
  // };

  function onInputchange(event) {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  }

  // function setFiles(files) {
  //   setFormValues({ ...formValues, files: files });
  // }

  function checkRequiredFields() {
    const requiredFields = inputFields.filter((field) => field.Required === 'TRUE');
    if (requiredFields) {
      for (const field of requiredFields) {
        if (!formValues[field['Input Name']]) {
          return false;
        }
      }
    }
    return true;
  }

  function saveForm() {
    const formData = new FormData();
    Object.values(formValues?.files).forEach((file) => {
      formData.append('arrayOfFiles', file);
    });
    // formData.append('user', auth?.auth?.rep);
    formData.append('formValues', JSON.stringify(formValues));
    useWebsiteAPI
      .post(`forms/customreq`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        setSubmitComplete(true);
      })
      .catch((err) => {
        setNotification(`${err}`);
      });
  }

  function submitHandler(event) {
    event.preventDefault();
    if (continueValid) {
      saveForm();
    } else {
      setInvalidMessage(true);
    }
  }

  useEffect(() => {
    const checkFields = checkRequiredFields();
    if (checkFields) {
      setContinueValid(true);
      setInvalidMessage(false);
    } else {
      setContinueValid(false);
    }
  }, [formValues]);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center' }}
      className='claimForm'
    >
      <div className='tile-layout'>
        <HeaderWithActions text={'NPS Users'} />
        <div
          role='button'
          aria-label='Go Back'
          tabIndex={0}
          className='portal-icon-back'
          onClick={() => navigate('/users')}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              navigate('/users');
            }
          }}
        >
          <BiArrowBack />
          BACK
        </div>
        {!submitComplete ?
          <form className='tile-layout'>
            <div
              className='tile-div center custom-form'
              style={{ flexDirection: 'column', gap: '40px', width: '75%' }}
            >
              <div
                className='portal-icon-header'
                style={{ margin: '0px' }}
              >
                Custom Requirement Form
              </div>
              {inputFields?.map((i, index) => {
                //   <InputField
                //     data={i}
                //     key={index}
                //     onInputchange={onInputchange}
                //     formValues={formValues}
                //     files={formValues.files}
                //     setFiles={setFiles}
                //   />
                return (
                  <FormInput
                    key={index}
                    inputType={i['Input Type']}
                    legend={i['Placeholder Text']}
                    name={i['Placeholder Text']}
                    // type='text'
                    // value={}
                    placeholder={`Enter ${i['Placeholder Text']}`}
                    required={true}
                    asterisk={i.Required}
                    onChange={() => {
                      onInputchange();
                    }}
                  />
                );
              })}

              <BasicButton
                text='Submit'
                onClick={(e) => {
                  submitHandler(e);
                }}
                type='white-bordered-button basic-button-design'
              />
            </div>
            <div className='error-msg-text'>
              {invalidMessage ? 'Required field is missing.' : ''}
            </div>
          </form>
        : <>
            <form className='success-form'>
              <img
                alt='check'
                src={check}
              ></img>
              <h2>Thank you for submitting your request!</h2>
              <div className='underline'></div>
            </form>
            {notification ?
              <div className='notice'>{notification}</div>
            : ''}
          </>
        }
      </div>
    </div>
  );
};

export default EmployeePortalCustomForm;
