import { useEffect, useState } from 'react';
import useWebsiteAPI from '../../Api/websiteAPI';
import AccountDropdown from '../../Components/ReusableElements/AccountDropdown';
import NotificationIcon from '../../Components/ReusableElements/NotificationIcon';
import '../../CSS/HeaderNav.css';
import '../../CSS/Mobile/M_HeaderNav.css';
import useAuth from '../../Hooks/useAuth';
// import { io } from 'socket.io-client';

const PortalHeader = ({ setChatOpen, getMessages }) => {
  // const socket = io(process.env.REACT_APP_SERVER_API_ADDRESS);
  const { auth } = useAuth();
  const websiteAPI = useWebsiteAPI();
  const [allMessages, setAllMessages] = useState([]);

  useEffect(() => {
    getAllMessages();
  }, []);

  // useEffect(() => {
  //   socket.on("chat message", (results) => {
  //     setAllMessages([...results]);
  //   });

  //   socket.on("message read", (results) => {
  //     setAllMessages([...results]);
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  async function getAllMessages() {
    await websiteAPI
      .get(`portalcontent/getAllMessages`)
      .then((response) => {
        setAllMessages(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <>
      <div
        id='portal-fixed-height'
        className='portal-header-two portal-header-fixed '
      >
        <div className='padding'>
          <header
            style={{ margin: '0px' }}
            className='basic-flex App-header'
          >
            <img
              style={{ width: '21rem' }}
              src='https://res.cloudinary.com/da3rom333/image/upload/v1712853625/Website%20Assets/All%20Icons/New%20Icons/NPS_Portal_-_Logo_v2_bpkkv5.svg'
              alt='NPS Portal Logo'
            ></img>
            <div className='basic-flex'>
              <AccountDropdown />
              <NotificationIcon
                getMessages={getMessages}
                setChatOpen={setChatOpen}
                texts={
                  (
                    allMessages?.length &&
                    allMessages?.filter((i) => i.seen === 0 && i.name !== auth?.rep)?.length
                  ) ?
                    allMessages?.filter((i) => i.seen === 0 && i.name !== auth?.rep)
                  : ''
                }
              />
            </div>
          </header>
        </div>
      </div>
      <div style={{ height: '150px' }}></div>
    </>
  );
};

export default PortalHeader;
