import React from 'react';
import '../../CSS/TileLayout.css';

function HeaderWithActions({ text, actions }) {
  return (
    <div className='header-action-div'>
      <div>{text}</div>
      <div className='header-icon-div'>
        {actions?.map(({ icon, action }, index) => (
          <div
            key={index}
            role='button'
            tabIndex={0}
            className='header-action-icon'
            onClick={action}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                action();
              }
            }}
          >
            {icon}
          </div>
        ))}
      </div>
    </div>
  );
}

export default HeaderWithActions;
