import useAuth from '../Hooks/useAuth';
import useAxiosJWT from '../Hooks/useAxiosJWT';

const useWebsiteAPI = () => {
  const BASE_URL = `${process.env.REACT_APP_SERVER_API_ADDRESS}`;
  const { auth } = useAuth();
  const axiosJWT = useAxiosJWT();

  return axiosJWT.create({
    baseURL: BASE_URL,
    timeout: 9000,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth?.accessToken}`,
      UserName: auth?.rep,
    },
  });
};

export default useWebsiteAPI;
