import { useEffect, useState } from 'react';
import HeaderItem from '../../Components/Layout/HeaderItem';

import useWebsiteAPI from '../../Api/websiteAPI';
import useAuth from '../../Hooks/useAuth';

const HeaderNavLinks = ({ setWarningPopup = false, setLink = false, portal = false }) => {
  const [categories, setCategories] = useState([]);
  /* eslint-disable no-unused-vars */
  const [envDrop, setEnvDrop] = useState();
  const [mediaDrop, setMediaDrop] = useState();
  const [dealerDrop, setDealerDrop] = useState();
  const [aboutDrop, setAboutDrop] = useState();
  const [productCategories, setProductCategories] = useState([]);
  /* eslint-enable no-unused-vars */
  const { auth } = useAuth();
  const websiteAPI = useWebsiteAPI();

  function getCategories() {
    websiteAPI.get(`products/getCategories`).then((response) => {
      setCategories(response.data);
    });
  }

  function getData() {
    websiteAPI.post(`content/getDropDownTabs`, {}).then((response) => {
      setEnvDrop(response.data.dropdown.filter((i) => i.tab.toLowerCase() === 'environment'));
      setMediaDrop(response.data.dropdown.filter((i) => i.tab.toLowerCase() === 'media'));
      (
        auth?.accounts
          ?.find((i) => i.last_login === 1)
          ?.AccountRoles?.some((role) => [1100, 1200].includes(role))
      ) ?
        setDealerDrop(response.data.dropdown.filter((i) => i.tab.toLowerCase() === 'dealer'))
      : setDealerDrop(
          response.data.dropdown
            .filter((i) => i.tab.toLowerCase() === 'dealer')
            .filter((i) => i.ID !== 50)
        );
      setAboutDrop(response.data.dropdown.filter((i) => i.tab.toLowerCase() === 'about'));
      setProductCategories(
        response.data.dropdown.filter((i) => i.tab.toLowerCase() === 'products')
      );
    });
  }
  useEffect(() => {
    getCategories();
    getData();
    return () => {
      setCategories([]);
    };
  }, []);

  useEffect(() => {
    getData();
  }, [auth?.rep]);

  return (
    <div className='header-nav-links'>
      <HeaderItem
        itemText={"WHAT'S NEW"}
        link={'/whats-new'}
        setWarningPopup={setWarningPopup}
        setLink={setLink}
        portal={portal}
      />
      <HeaderItem
        itemText={'PRODUCTS'}
        categories={categories}
        link={'/categories'}
        setWarningPopup={setWarningPopup}
        setLink={setLink}
        portal={portal}
      />
      <HeaderItem
        itemText={'ENVIRONMENTS'}
        link={'/environments'}
        setWarningPopup={setWarningPopup}
        setLink={setLink}
        portal={portal}
      />
      <HeaderItem
        itemText={'MEDIA CENTER'}
        link={'/media-center'}
        setWarningPopup={setWarningPopup}
        setLink={setLink}
        portal={portal}
      />
      <HeaderItem
        itemText={'DEALER PORTAL'}
        link={'/dealer-portal'}
        setWarningPopup={setWarningPopup}
        setLink={setLink}
        portal={portal}
      />
      <HeaderItem
        itemText={'ABOUT'}
        link={'/about'}
        setWarningPopup={setWarningPopup}
        setLink={setLink}
        portal={portal}
      />
    </div>
  );
};

export default HeaderNavLinks;
