import './App.css';
import RouteDirectory from './RouteDirectory';

function App() {
  return (
    <div className='App'>
      <RouteDirectory />
    </div>
  );
}

export default App;
