import React, { useEffect, useState } from 'react';
import { MdSort } from 'react-icons/md';
import { TbColumns3 } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import useWebsiteAPI from '../Api/websiteAPI';
import OrdersTableData from '../Components/DataTable/OrdersTableData';
import OrdersTableHeader from '../Components/DataTable/OrdersTableHeader';
import GetOrdersByAccount from '../Components/HomePage/GetOrdersByAccount';
import BasicButton from '../Components/ReusableElements/basicButton';
import BasicSearchBar from '../Components/ReusableElements/BasicSearchBar';
import Tabs from '../Components/ReusableElements/Tabs';
import LoadingIcon from '../Components/Shared/LoadingIcon';
import Paginator from '../Components/Shared/Paginator';
import { useCart } from '../Context/CartContext';
import useAuth from '../Hooks/useAuth';

import ClaimsPopup from '../Components/DataTable/ClaimsPopup';
function OrdersTable({ tableName, tableType, tableData, setMainTable }) {
  const { auth } = useAuth();
  const websiteAPI = useWebsiteAPI();
  const { notificationData, accountChosen, searchAccount } = useCart();
  const navigate = useNavigate();
  const [table, setTable] = useState({
    name: tableName,
    type: tableType,
    data: tableData,
  });
  const [animationClass, setAnimationClass] = useState('');
  const [tabs, setTabs] = useState();
  const [filter, setFilter] = useState({});
  // const [sort, setSort] = useState();
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState();
  const [columns, setColumns] = useState();
  const [allRows, setAllRows] = useState();
  const [rows, setRows] = useState([]);
  const [openRow, setOpenRow] = useState();
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState();
  const [show, setShow] = useState(32);
  const [selectedTable, setSelectedTable] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [orderTicketTypes, setOrderTicketTypes] = useState([]);
  const [selectedClaims, setSelectedClaims] = useState([]);
  const [notification, setNotification] = useState('');
  const [claimPopup, setClaimPopup] = useState('');
  const [statusIcons, setStatusIcons] = useState();

  async function getOrderTicketTypes() {
    await websiteAPI
      .get(`portalContent/getOrderTicketTypes`)
      .then((response) => {
        setOrderTicketTypes(response.data);
      })
      .catch((error) => {
        console.error('Error fetching order ticket types:', error);
      });
  }

  function getTabs() {
    //get the right tabs for that display from the db
    if (table.type === 'customerOrders') {
      setTabs([
        { title: 'All Orders', action: () => setFilter({}) },
        { title: 'Support Desk', action: () => {} },
        { title: 'On Hold', action: () => setFilter({ status: ['On Hold'] }) }, //take out this tab
      ]);
    } else {
      setTabs();
    }
  }

  async function getColumns() {
    await websiteAPI
      .get(`portalContent/getTableColumns?table=${table.type}`)
      .then((response) => {
        setColumns(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async function getAllRows() {
    setAllRows([]);
    //set rows for other table types, not for 'customerOrders' because that is set in GetOrdersByAccount.js
    if (table.type === 'customerOrderDetails' || table.type === 'customerOrderSupport') {
      const order = table.data.order;
      console.log('order', order);
      await websiteAPI
        .post(`orders/orderItems`, {
          orderNum: order.orderNumOriginal,
          orderNumPart: order.orderNumPart,
          account: accountChosen?.customerId || null,
        })
        .then((response) => {
          setAllRows(response.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async function getRows(/*filter, sort, search*/) {
    const newRows = [];
    //sort thru allRows based on filter, sort and search
    // if (Object.keys(filter).length) {
    //     Object.keys(filter).forEach(fltr => {
    //         newRows.filter(row => {
    //             if (!filter[fltr].includes(row[fltr])) {
    //                 return row;
    //             }
    //         })
    //     })
    // }
    allRows?.map((row) => {
      if (Object.keys(filter).length) {
        let match = true;
        Object.keys(filter).forEach((fKey) => {
          if (!(Object.keys(row)?.includes(fKey) && filter[fKey].includes(row[fKey]))) {
            match = false;
          }
        });
        if (match) {
          newRows.push(row);
        }
      } else {
        newRows.push(row);
      }
    });
    setRows(newRows);
    // setRows(allRows);
  }

  function handleOpenRow(rowNum) {
    if (columns.find((c) => c.type === 'expand')) {
      if (openRow === rowNum) {
        setOpenRow();
      } else {
        setOpenRow(rowNum);
      }
    }
  }

  function openClaimPopup() {
    if (
      (selectedRows.length === selectedClaims.length && selectedRows?.length > 0) ||
      (selectedTable && selectedClaims.length > 0)
    ) {
      if (selectedTable) {
        setClaimPopup({ num: 0 });
      } else {
        setClaimPopup({ num: selectedRows[0] });
      }
    } else {
      setNotification('Please choose a help option for selected items.');
    }
  }

  async function getStatusIcons() {
    await websiteAPI
      .get(`products/statusIcons`)
      .then((response) => {
        setStatusIcons(response.data);
      })
      .catch((error) => {
        console.error('Error fetching status icons:', error);
      });
  }

  useEffect(() => {
    if (notificationData?.some((i) => i.Path === '/track-order')) {
      navigate('/unauthorized');
    }
    document.title = `NPS Portal- Track Order`;
  }, []);

  useEffect(() => {
    setOpenRow();
    setSelectedTable();
    setSelectedRows([]);
    getTabs();
    getColumns();
    getAllRows();

    if (table.data?.display === 'inlineTable') {
      setAnimationClass('slide-down');
    } else {
      setAnimationClass('slide-in');
    }
    const timer = setTimeout(() => {
      setAnimationClass('');
    }, 1000); // Match the duration of the animation

    return () => clearTimeout(timer);
  }, [table]);

  useEffect(() => {
    getOrderTicketTypes();
    getStatusIcons();
  }, []);

  useEffect(() => {
    getRows();
  }, [allRows, filter]);

  useEffect(() => {
    if (rows?.length > 0) {
      setLastPage(Math.ceil(rows.length / show));
    }
    setPage(0);
  }, [rows]);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification(''); // Clear the notification after 2 seconds
      }, 2000);

      // Cleanup function to clear the timer
      return () => clearTimeout(timer);
    }
  }, [notification]);

  return (
    <div className={`track-order ${animationClass}`}>
      {table.data?.display !== 'inlineTable' && (
        <>
          <h1>{table.name.toUpperCase()}</h1>
          {table.type === 'customerOrders' && ( //auth?.roles?.includes(3500) &&
            <GetOrdersByAccount
              myOrders={allRows}
              setMyOrders={setAllRows}
              loading={loading}
              setLoading={setLoading}
            />
          )}
          {tabs && (!auth?.roles?.includes(3500) || (accountChosen && !searchAccount)) && (
            <>
              <div className='order-tabs'>
                <Tabs tabs={tabs} />
              </div>
              <div className='search-div'>
                <BasicSearchBar
                  filters={search}
                  setFilters={setSearch}
                />
                <span>
                  <TbColumns3 /> Columns
                </span>
                <span>
                  <MdSort /> Sort
                </span>
              </div>
            </>
          )}
        </>
      )}

      {loading ?
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
          }}>
          <LoadingIcon />
        </div>
      : (
        table.data?.display !== 'inlineTable' &&
        ((rows.length <= 0 && !auth?.roles?.includes(3500)) ||
          (rows.length <= 0 && accountChosen && !searchAccount))
      ) ?
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
          }}>
          <p style={{ padding: '0px 26px' }}>We're sorry, there are no results for your search.</p>
        </div>
      : rows.length <= 0 && auth?.roles?.includes(3500) && !accountChosen ?
        <></>
      : rows.length > 0 && (
          <table className='order-table'>
            <thead>
              <OrdersTableHeader
                headerType={table.type}
                columns={columns}
                allRows={allRows}
                table={table}
                setMainTable={setMainTable}
                selectedTable={selectedTable}
                setSelectedTable={setSelectedTable}
                filter={filter}
                setFilter={setFilter}
                orderTicketTypes={orderTicketTypes}
                setSelectedClaims={setSelectedClaims}
                selectedClaims={selectedClaims}
                setSelectedRows={setSelectedRows}
              />
            </thead>
            <tbody>
              {rows?.slice(show * page, show * page + show).map((row, i) => {
                return (
                  <>
                    <tr
                      key={i}
                      className={`order-row ${
                        openRow ?
                          openRow !== row.orderNum ?
                            'greyed-out'
                          : 'open-order'
                        : table.data?.display === 'inlineTable' ? 'inline-table-row'
                        : selectedRows.includes(i) || selectedTable ? 'selected'
                        : ''
                      }`}
                      onClick={() => {
                        if (openRow === row.orderNum) {
                          handleOpenRow();
                        } else {
                          handleOpenRow(row.orderNum);
                        }
                      }}>
                      {columns.map((column, index) => {
                        return (
                          <td
                            className={column.type}
                            key={index}>
                            <OrdersTableData
                              rowIndex={i}
                              row={row}
                              column={column}
                              openRow={openRow}
                              handleOpenRow={handleOpenRow}
                              selectedRows={selectedRows}
                              setSelectedRows={setSelectedRows}
                              selectedTable={selectedTable}
                              setSelectedTable={setSelectedTable}
                              orderTicketTypes={orderTicketTypes}
                              selectedClaims={selectedClaims}
                              setSelectedClaims={setSelectedClaims}
                              statusIcons={statusIcons}
                            />
                          </td>
                        );
                      })}
                    </tr>
                    {openRow === row.orderNum && (
                      <tr key={`openRow`}>
                        <td colSpan='6'>
                          <OrdersTable
                            tableName={`Order #${row.poNum}`}
                            tableType='customerOrderDetails'
                            tableData={{ order: row, display: 'inlineTable' }}
                            setMainTable={setTable}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
          </table>
        )
      }
      {rows.length > 16 && table.data?.display !== 'inlineTable' && (
        <Paginator
          currentPage={page}
          totalPages={lastPage}
          setCurrentPage={setPage}
          setShow={setShow}
          show={show}
        />
      )}
      {table.type === 'customerOrderSupport' && (
        <div className='order-buttons-div'>
          <BasicButton
            text={'Back'}
            type={'basic-white-button'}
            onClick={() => setTable({ name: 'Orders', type: 'customerOrders' })}
          />
          <BasicButton
            text={'Continue'}
            type={'white-bordered-button'}
            onClick={() => openClaimPopup()}
            //onClick needs to be set to continue the process for filing claims
          />
        </div>
      )}
      {notification ?
        <div
          style={{
            fontSize: '16px',
            top: '47.7rem',
            marginLeft: '4.8rem',
            width: '520px',
            zIndex: '152',
          }}
          className='notice'>
          {notification}
        </div>
      : ''}
      {allRows && claimPopup && (
        <ClaimsPopup
          claimPopup={claimPopup}
          setClaimPopup={setClaimPopup}
          selectedTable={selectedTable}
          selectedRows={selectedRows}
          allRows={allRows}
          orderTicketTypes={orderTicketTypes}
          selectedClaims={selectedClaims}
          setNotification={setNotification}
        />
      )}
      {/* {allRows && claimPopup ? (
        <ReusablePopup
          innerComponent={
            selectedClaims?.find((i) => i.index === claimPopup.num)?.value === 'Missing Parts' ?
              <MissingPartsPopup
                allRows={allRows}
                orderTicketTypes={orderTicketTypes}
                claimPopup={claimPopup}
                selectedClaims={selectedClaims}
              />
            : (
              selectedClaims?.find((i) => i.index === claimPopup.num)?.value ===
              'Returns & Cancellations'
            ) ?
              <ReturnsPopup
                allRows={allRows}
                orderTicketTypes={orderTicketTypes}
                claimPopup={claimPopup}
                selectedClaims={selectedClaims}
              />
            : selectedClaims?.find((i) => i.index === claimPopup.num)?.value === 'Freight Damage' ?
              <FreightDamagePopup
                allRows={allRows}
                orderTicketTypes={orderTicketTypes}
                claimPopup={claimPopup}
                selectedClaims={selectedClaims}
              />
            : selectedClaims?.find((i) => i.index === claimPopup.num)?.value === 'Warranty' ?
              <WarrantyPopup
                allRows={allRows}
                orderTicketTypes={orderTicketTypes}
                claimPopup={claimPopup}
                selectedClaims={selectedClaims}
              />
            : ''
          }
          data={selectedTable ? allRows?.map((i, index) => index) : selectedRows}
          open={claimPopup}
          setOpen={setClaimPopup}
          continueAction={() => {
            setClaimPopup(
              (
                (selectedTable && allRows.length > claimPopup?.num + 1) ||
                  selectedRows.length > selectedRows.indexOf(claimPopup?.num) + 1
              ) ?
                selectedTable ?
                  {
                    num: claimPopup?.num + 1,
                  }
                : {
                    num: selectedRows[selectedRows.indexOf(claimPopup?.num) + 1],
                  }
              : false
            );
          }}
        />
      ) : (
        ''
      )} */}
    </div>
  );
}

export default OrdersTable;
