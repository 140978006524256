import React, { useState } from 'react';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import '../../CSS/AdminPortal.css';
import '../../CSS/EditableFormDesign.css';
import '../../CSS/FreightCart.css';
import '../../CSS/MonthOrders.css';

const TopProducts = () => {
  const [products, setProducts] = useState([
    {
      sku: '6218',
      series: '6200 Series',
      image:
        'https://res.cloudinary.com/da3rom333/image/upload/v1674571914/Product%20Assets/Stools/6200%20Series/6218_1_sk5kc0.jpg',
      link: '/products/6200 Series',
    },
    // {
    //   sku: "RDG6-5PT04",
    //   series: "ROBO Series",
    //   image:
    //     "https://res.cloudinary.com/da3rom333/image/upload/v1674747812/Product%20Assets/Room%20Dividers/RDG6-5PT04_2_ikr04q.jpg",
    //   link: "/products/ROBO Series",
    // },
    {
      sku: 'WSHP-CBESGF',
      series: 'Workstation Hexagon',
      image:
        'https://res.cloudinary.com/da3rom333/image/upload/v1702925352/Product%20Assets/Workstations/Hexagon%20Workstations%20Series/WSHP-CBSESGF.png',
      link: '/products/Workstation Hexagon',
    },
    // {
    //   sku: "MTFB10-MDPECRxxxx",
    //   series: "Mobile Table",
    //   image:
    //     "https://res.cloudinary.com/da3rom333/image/upload/v1678896270/Product%20Assets/Tables/MTFB%20Series/MTFB12-CRGYGY_1_yhjzdv.jpg",
    //   link: "/products/Mobile Table",
    // },
  ]);

  //   useEffect(() => {
  //     getTopProduct();
  //   }, []);

  //   async function getTopProduct() {
  //     await axiosJWT
  //       .get(`/portalcontent/getTopProductOrdered`)
  //       .then((response) => {
  //         setProduct(response.data);
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //       });
  //   }

  return (
    <div
      style={{
        marginLeft: '2rem',
        width: '400px',
        height: '328px',
        marginTop: '1rem',
      }}
      className='orders-of-month-div'
    >
      <div
        style={{ padding: '0px' }}
        className='annual-balance-text'
      >
        Top Products
      </div>
      {products.map((product, index) => (
        <div
          role='button'
          aria-label={product.series}
          tabIndex={0}
          key={index}
          style={{
            alignItems: 'center',
            border: '2px solid var(--lightgrey-50)',
            borderRadius: '10px',
            marginBottom: '20px',
            padding: '10px',
            width: '375px',
            justifyContent: 'space-between',
            cursor: 'pointer',
          }}
          onClick={() => {
            window.location.href = `${process.env.REACT_APP_MAIN_WEBSITE}${product?.link}`;
          }}
          className='amount-div'
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              window.location.href = `${process.env.REACT_APP_MAIN_WEBSITE}${product?.link}`;
            }
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              style={{ width: '70px', height: '70px' }}
              className='top-product-image'
              src={`${product.image}`}
              alt={product.series}
            ></img>
            <div>
              <div
                style={{
                  font: 'normal normal 500 25px/ 27px Poppins',
                  textAlign: 'start',
                }}
                className='orders-amount'
              >
                {product.series}
              </div>
              <div
                style={{ textAlign: 'start', margin: '0px' }}
                className='items-text'
              >
                {product.sku}
              </div>
            </div>
          </div>
          <MdOutlineKeyboardArrowRight
            style={{ width: '3rem', height: '3rem', color: 'var(--lightgrey)' }}
          />
        </div>
      ))}
    </div>
  );
};

export default TopProducts;
