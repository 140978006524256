import React, { useEffect, useState } from 'react';
import useWebsiteAPI from '../Api/websiteAPI';
import '../CSS/Dashboard.css';
import '../CSS/Home.css';
import AnnualBalance from '../Components/HomePage/AnnualBalance';
import HomeCarousel from '../Components/HomePage/Carousel';
import MonthOrders from '../Components/HomePage/MonthOrders';
import NPSCalendarEvents from '../Components/HomePage/NPSCalendarEvents';
import TopProduct from '../Components/HomePage/TopProduct';
import TopProducts from '../Components/HomePage/TopProducts';
import useAuth from '../Hooks/useAuth';

const Dashboard = () => {
  const { auth } = useAuth();
  const websiteAPI = useWebsiteAPI();

  const [banner, setBanner] = useState();

  useEffect(() => {
    getTopVideo();
  }, []);

  async function getTopVideo() {
    websiteAPI
      .post(`content/getCarouselBanners`, {
        page: 'whatsnew',
      })
      .then((response) => {
        setBanner(
          response.data.filter(
            (i) =>
              i.carousel_type.toLowerCase() === 'main' &&
              i.screen_type?.toLowerCase() === 'desktop' &&
              i.sort === 1
          )
        );
      });
  }

  return (
    <>
      <div className='portal-top-div'>
        <img
          className='portal-top-image'
          src='https://res.cloudinary.com/da3rom333/image/upload/v1688650802/Website%20Assets/Banners/V4_im0hjw.svg'
          alt='Portal Banner'
        ></img>
        <div className='portal-welcome-div'>
          <div className='portal-hello-text'>Hello {auth?.rep}!</div>
          <div className='portal-welcome-text'>Welcome to your NPS Portal</div>
        </div>
        <div style={{ display: 'flex' }}>
          <div>
            {banner && <HomeCarousel images={banner} />}
            <div
              style={{ justifyContent: 'start' }}
              className='basic-flex'
            >
              <MonthOrders />
              <TopProduct />
            </div>
            <AnnualBalance />
          </div>
          <div>
            <TopProducts />
            <NPSCalendarEvents />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
