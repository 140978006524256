import { createContext, useContext, useEffect, useState } from 'react';
import useWebsiteAPI from '../Api/websiteAPI';
import CompleteItemPrice from '../Components/Shared/completeItemPrice';
import useAuth from '../Hooks/useAuth';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const { auth } = useAuth();
  const websiteAPI = useWebsiteAPI();
  const getItemPrice = CompleteItemPrice();
  const [cart, setCart] = useState([]);
  const [notificationData, setNotificationData] = useState();
  const [accountChosen, setAccountChosen] = useState();
  const [searchAccount, setSearchAccount] = useState();

  const updateQty = async (newQty, newTotalQty, sku) => {
    await updateCart([{ ['qty']: newQty, ['totalQty']: newTotalQty }], sku);
  };

  const updateNB = async (nb, sku) => {
    await updateCart([{ isNonBoxed: nb === true ? 1 : 0 }], sku);
  };

  const updatePricing = async (baseSku, uom, optionsSelected, sku) => {
    const response = await getItemPricing(baseSku, uom, optionsSelected, sku);
    const price =
      response?.basePrice?.unitPrice < 1 || !response?.basePrice?.unitPrice ?
        'Custom Pricing'
      : response.basePrice.unitPrice;
    const upcharge =
      (Number(response?.laminatesUpcharge?.unitPrice) || Number(response?.laminatesUpcharge)) +
      (Number(response?.premEdgeUpcharge?.unitPrice) || Number(response?.premEdgeUpcharge));
    const finalPrice = price !== 'Custom Pricing' ? price + upcharge : price;
    updateCart([{ price: finalPrice }], sku);
    // if (
    //   auth?.accounts
    //     ?.find((account) => account.last_login === 1)
    //     ?.AccountRoles?.some((role) => [1100, 1200, 1300].includes(role))
    // ) {
    //   logPricing(sku, response);
    // }
  };

  const getCart = async () => {
    const response = await websiteAPI.get('NPS/getCart');
    return response.data;
  };

  const savecarttoDB = async (cartIn) => {
    await websiteAPI
      .post('NPS/saveCart', {
        cart: cartIn,
      })
      .then((response) => {
        setCart(response.data || []);
      });
  };

  const updateCart = async (newCart, item) => {
    await websiteAPI
      .post('NPS/updateCart', {
        newCart,
        item,
      })
      .then((response) => {
        setCart(response.data || []);
      });
  };

  // useEffect(async () => {
  //   if (auth?.roles?.includes(3500) || auth?.roles?.includes(3001)) {
  //     // const timeout = setTimeout(async () => {
  //     //   await getCart();
  //     // }, 10000);

  //     // return () => clearTimeout(timeout);
  //     const cart = await getCart();
  //     setCart(cart);
  //   }
  // }, [auth?.roles]);

  // useEffect(async () => {
  //   // const timeout = setTimeout(async () => {
  //   //   await getCart();
  //   // }, 10000);

  //   // return () => clearTimeout(timeout);
  //   const cart = await getCart();
  //   setCart(cart);
  // }, []);

  const addToCart = async (
    modelNum,
    qty,
    skuDisplayed,
    shortDescription,
    image,
    optionsSelected,
    baseSku,
    totalQty,
    uom,
    availableNB = false,
    isNB = false,
    status,
    customerPrice = false
  ) => {
    try {
      const cart = await getCart();
      const index = cart?.findIndex((e) => e.displaySku === skuDisplayed);
      if (modelNum && qty > 0) {
        if (index > -1) {
          updateQty(qty + cart[index].qty, totalQty + cart[index].totalQty, skuDisplayed);
        } else {
          if (status === 'D') {
            return 'CANNOT ADD TO CART - DICONTINUED ITEM';
          } else {
            let completePrice;

            if (customerPrice !== false) {
              var price =
                customerPrice?.basePrice?.unitPrice < 1 || !customerPrice?.basePrice?.unitPrice ?
                  'Custom Pricing'
                : customerPrice.basePrice.unitPrice;
              var upcharge =
                (Number(customerPrice?.laminatesUpcharge?.unitPrice) ||
                  Number(customerPrice?.laminatesUpcharge)) +
                (Number(customerPrice?.premEdgeUpcharge?.unitPrice) ||
                  Number(customerPrice?.premEdgeUpcharge));
              completePrice = price !== 'Custom Pricing' ? price + upcharge : price;
            }
            const newItem = {
              qty: qty,
              uom: uom,
              item: modelNum,
              image: image ? image : null,
              price: customerPrice !== false ? completePrice : null,
              baseSku: baseSku,
              totalQty: totalQty,
              displaySku: skuDisplayed,
              isNonBoxed: isNB,
              description: shortDescription,
              optionsSelected:
                optionsSelected ? JSON.stringify(optionsSelected) : JSON.stringify({}),
              availableNonBoxed: availableNB?.toLowerCase() === 'y',
            };

            savecarttoDB(newItem);

            if (customerPrice === false && auth?.accounts?.length > 0) {
              updatePricing(baseSku, uom, optionsSelected, skuDisplayed);
            }
            // else {
            //   if (
            //     auth?.accounts
            //       ?.find((account) => account.last_login === 1)
            //       ?.AccountRoles?.some((role) =>
            //         [1100, 1200, 1300].includes(role)
            //       )
            //   ) {
            //     logPricing(skuDisplayed, customerPrice);
            //   }
            // }
          }
        }
      }
    } catch (error) {
      console.error('Error in addToCart:', error);
    }
  };

  const removeFromCart = async (sku) => {
    await websiteAPI
      .post('NPS/removeFromCart', {
        item: sku ? sku : null,
      })
      .then((response) => {
        setCart(response.data || []);
      });
  };

  const clearCart = () => {
    removeFromCart();
  };

  async function getItemPricing(baseSku, uom, optionsSelected, sku) {
    try {
      if (auth?.accounts?.length > 0 && auth?.roles?.includes(3001)) {
        const response = await getItemPrice(
          {
            modelNum: baseSku,
            quantity: uom,
            fullSku: sku,
          },
          null,
          optionsSelected
        );

        return response;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching customer prices for cart:', error);
    }
  }

  async function getNotificationData() {
    await websiteAPI
      .get(`content/getBannerNotifications`)
      .then((response) => {
        const currentDate = new Date();

        setNotificationData(
          response.data
            ?.filter((obj) => {
              return (
                obj['On/Off'] === 1 ||
                (obj.Active === 1 &&
                  obj['Start Date'] &&
                  obj['End Date'] &&
                  new Date(obj['Start Date']) <= currentDate &&
                  currentDate <= new Date(obj['End Date']))
              );
            })
            ?.filter((i) => i.type === 'flag')
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    getNotificationData();
  }, []);

  return (
    <CartContext.Provider
      value={{
        cart,
        updateQty,
        updateNB,
        addToCart,
        removeFromCart,
        clearCart,
        notificationData,
        accountChosen,
        setAccountChosen,
        searchAccount,
        setSearchAccount,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
