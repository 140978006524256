import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../CSS/PortalMainNav.css';

const HeaderItem = ({
  itemText,
  openDefault,
  link,
  setWarningPopup = false,
  setLink = false,
  portal = false,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(openDefault);

  function mouseEnter() {
    if (!portal) {
      setOpen(true);
    }
  }
  function mouseExit() {
    if (!openDefault && !portal) {
      setOpen(false);
    }
  }

  function mouseClick() {
    if (!openDefault && !portal) {
      setOpen(false);
      navigate(`/${link}`);
    } else if (portal) {
      setWarningPopup(true);
      setLink(link);
    }
  }

  return (
    <div>
      <li
        className='nav-item'
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseExit}
      >
        <div
          role='button'
          aria-label={itemText}
          tabIndex={0}
          onClick={mouseClick}
          className='nav-item-a'
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              mouseClick();
            }
          }}
        >
          {itemText}
        </div>
      </li>
      {/* {warningPopup && portal && (
        <ReusablePopup
          innerComponent={
            <div className="leave-portal-popup">
              <h2 className="leave-portal-title">
                Do you want to leave the NPS Portal?
              </h2>
              <div className="leave-portal-text">
                If you want to go to the main page, click continue.
              </div>{" "}
            </div>
          }
          open={warningPopup}
          setOpen={setWarningPopup}
          continueAction={() => navigate("/" + link)}
        />
      )} */}
    </div>
  );
};

export default HeaderItem;
