import useWebsiteAPI from '../Api/websiteAPI';
import useAuth from './useAuth';

const useChangeAccount = () => {
  const { auth } = useAuth();
  const websiteAPI = useWebsiteAPI();

  async function changeAccount(accountNum) {
    await websiteAPI
      .post(`account/changeAccount`, {
        userID: auth?.accounts[0].user_ID,
        accountNum: accountNum,
      })
      .then(() => {
        if (window.location.pathname === '/unauthorized') {
          window.location = document.referrer;
        } else {
          window.location.reload();
        }
      });
  }

  return changeAccount;
};

export default useChangeAccount;
