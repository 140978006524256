import { useEffect, useRef, useState } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import '../../CSS/reusableComponents.css';

const BasicDropdown = ({ list, title = false, chosen, setChosen, selectMultiple = false }) => {
  const [openList, setOpenList] = useState(false);
  const ref = useRef(null);

  function handleClick(item) {
    if (!selectMultiple) {
      setChosen(item);
      setOpenList(false);
    } else {
      if (chosen.find((i) => i.id === item.id)) {
        setChosen(chosen.filter((i) => i.id !== item.id));
      } else {
        setChosen([...chosen, item]);
      }
    }
  }

  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenList(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={ref}>
      <div
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setOpenList(!openList);
          }
        }}
        role='button'
        aria-label={title}
        tabIndex={0}
        onClick={() => setOpenList(!openList)}
        className={`basic-dropdown-tab ${
          (
            openList ||
            (chosen && !Array.isArray(chosen)) ||
            (chosen && Array.isArray(chosen) && chosen.length > 0)
          ) ?
            'dropdown-clicked'
          : ''
        }`}>
        <div className='basic-flex'>
          <div className='basic-dropdown-title'>
            {chosen && !Array.isArray(chosen) ? chosen.title : title}
          </div>
        </div>

        <MdKeyboardArrowDown
          className={`basic-dropdown-arrow ${openList ? 'rotate-up' : 'rotate-down'}`}
        />
      </div>
      {openList && (
        <div className='basic-dropdown-list'>
          {list?.map((i, index) => (
            <div
              role='button'
              aria-label={i.title}
              tabIndex={0}
              key={index}
              onClick={() => {
                handleClick(i);
              }}
              className='dropdown-entire-item'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleClick(i);
                }
              }}>
              {selectMultiple && (
                <input
                  type='checkbox'
                  className='checkbox'
                  checked={chosen.find((j) => j.id === i.id)}
                  onChange={() => {}}
                />
              )}
              {i.icon && (
                <img
                  className='dropdown-icon'
                  src={`${i.icon}`}
                  alt={`${i.title}`}></img>
              )}
              {i.img && (
                <img
                  className='dropdown-img'
                  src={i.img}
                  alt={`${i.title}`}></img>
              )}
              <div className='dropdown-list-item'>{i.title}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BasicDropdown;
