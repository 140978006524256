import { Route, Routes } from 'react-router-dom';
import PersistLogin from './Components/Login/PersistLogin';
// import RequireAuth from './Components/Login/RequireAuth';
import Dashboard from './Pages/Dashboard';
import OrdersTable from './Pages/OrdersTable';
import PortalLayout from './Pages/PortalLayout';
// import ReusableComponents from './Pages/ReusableComponents';
import PortalCustomForm from './Components/EmployeePortal/PortalCustomForm';
import PortalIcon from './Components/EmployeePortal/PortalIcons';
import Sublinks from './Components/EmployeePortal/Sublinks';
import TileLayout from './Pages/TileLayout';

function RouteDirectory() {
  return (
    <Routes>
      <Route element={<PersistLogin />}>
        <Route
          path='/'
          element={<PortalLayout />}>
          <Route path='/users'>
            <Route
              path=''
              element={<TileLayout title='NpsUsers' />}
            />
            <Route
              path='icons'
              element={<PortalIcon />}
            />
            <Route
              path='customForm'
              element={<PortalCustomForm />}
            />
            <Route
              path=':sublinks'
              element={<Sublinks />}
            />
          </Route>
          <Route
            path='/dealers'
            element={<TileLayout title='Dealer' />}
          />
          <Route
            path='/track-order'
            element={
              <OrdersTable
                tableName='Orders'
                tableType='customerOrders'
              />
            }
          />
          <Route
            path='portal'
            element={<Dashboard />}
          />
        </Route>
        {/* <Route
          path='reusableComponents'
          element={
            <RequireAuth>
              <ReusableComponents />
            </RequireAuth>
          }
        /> */}
      </Route>
    </Routes>
  );
}

export default RouteDirectory;
