import React, { useEffect, useState } from 'react';
import useWebsiteAPI from '../../Api/websiteAPI';
import '../../CSS/MonthOrders.css';

const TopProduct = () => {
  const websiteAPI = useWebsiteAPI();
  const [product, setProduct] = useState({});

  useEffect(() => {
    getTopProduct();
  }, []);

  async function getTopProduct() {
    await websiteAPI
      .get(`portalcontent/getTopProductOrdered`)
      .then((response) => {
        setProduct(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return product && product.base_sku ?
      <div
        style={{ marginLeft: '2.5rem', width: '400px' }}
        className='orders-of-month-div'
      >
        <div
          style={{ padding: '0px' }}
          className='annual-balance-text'
        >
          Your Top Product
        </div>
        <div
          style={{ alignItems: 'center' }}
          className='amount-div'
        >
          <img
            className='top-product-image'
            src={`${product.image}`}
            alt={product.base_sku}
          ></img>
          <div>
            <div
              style={{ fontSize: '30px', lineHeight: '40px', textAlign: 'start' }}
              className='orders-amount'
            >
              {product.base_sku}
            </div>
            <div
              style={{ textAlign: 'start', margin: '0px' }}
              className='items-text'
            >
              Total Ordered: {Math.round(product.total)}
            </div>
          </div>
        </div>
      </div>
    : <></>;
};

export default TopProduct;
