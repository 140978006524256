import { useEffect, useState } from 'react';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';

const HomeCarousel = ({ images }) => {
  const [index, setIndex] = useState(0);
  // const navigate = useNavigate();
  function addIndex() {
    if (index === images?.length - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  }

  function removeIndex() {
    if (index === 0) {
      setIndex(images?.length - 1);
    } else {
      setIndex(index - 1);
    }
  }

  useEffect(() => {
    if (images?.length > 1) {
      const timer = setInterval(() => {
        addIndex();
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [index]);

  // const [openVideo, setOpenVideo] = useState(false);
  // const [videoSrc, setVideoSrc] = useState();

  // function openVideoFunction(src) {
  //   setOpenVideo(true);
  //   setVideoSrc(src);
  // }

  return (
    <div>
      <div className='carouselOuterDiv'>
        {images?.length > 1 ?
          <button
            className='carouselButton'
            id='backButton'
            onClick={removeIndex}
          >
            <IoChevronBack />
          </button>
        : ''}

        <div className='carouselDiv selected'>
          {images?.map((i, indexIn) => {
            return (
              <img
                // onClick={() => {
                //   if (i.open_video) {
                //     openVideoFunction(i.link);
                //   } else {
                //     navigate(i.link);
                //   }
                // }}
                src={i.image}
                alt='home Page Banner'
                key={i.image}
                className={index === indexIn ? 'selected' : ''}
              ></img>
            );
          })}
        </div>
        {images?.length > 1 ?
          <button
            className='carouselButton'
            id='forwardButton'
            onClick={addIndex}
          >
            <IoChevronForward />
          </button>
        : ''}
      </div>
      {/* <CarouselDots images={images} index={index} setIndex={setIndex}></CarouselDots> */}
    </div>
  );
};

export default HomeCarousel;
