import React from 'react';
import HeaderWithActions from '../TileLayout/HeaderWithActions';
import { BiArrowBack } from 'react-icons/bi';
import '../../CSS/PortalIcons.css';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import '../../CSS/MediaDownloads.css';
import Tile from '../TileLayout/Tile';

function Sublinks() {
  const navigate = useNavigate();
  const location = useLocation();
  const links = location.state?.childLinks;

  return (
    <div className='tile-layout'>
      <HeaderWithActions text={'NPS Users'} />
      <div
        role='button'
        aria-label='Go Back'
        tabIndex={0}
        className='portal-icon-back'
        onClick={() => navigate('/users')}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            navigate('/users');
          }
        }}
      >
        <BiArrowBack />
        BACK
      </div>
      <Tile tile={links} />
    </div>
  );
}

export default Sublinks;
