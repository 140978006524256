import React, { useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import FilterOptions from '../DataTable/FilterOptions';
import BasicDropdown from '../ReusableElements/BasicDropdown';
import TicketType from '../ReusableElements/TicketType';
import BasicButton from '../ReusableElements/basicButton';

function OrdersTableHeader({
  headerType,
  columns,
  allRows,
  table,
  setMainTable,
  selectedTable,
  setSelectedTable,
  filter,
  setFilter,
  orderTicketTypes,
  setSelectedClaims,
  selectedClaims,
  setSelectedRows,
}) {
  const [openFilter, setOpenFilter] = useState();
  const [filterOptions, setFilterOptions] = useState();
  const [selectedTicket, setSelectedTicket] = useState();

  useEffect(() => {
    const options = {};
    allRows.forEach((row) => {
      columns.forEach((c) => {
        if (c.filterable) {
          if (!options[c.value]) {
            options[c.value] = [row[c.value]];
          } else if (!options[c.value].includes(row[c.value])) {
            options[c.value] = [...options[c.value], row[c.value]];
          }
        }
      });
    });
    setFilterOptions(options);
  }, []);

  useEffect(() => {
    if (selectedTable && selectedTicket) {
      if (selectedClaims.length > 0) {
        setSelectedClaims([]);
      } else {
        const newClaims = allRows.map((row, index) => ({
          index: index,
          value: selectedTicket.title,
        }));

        setSelectedClaims(newClaims);
      }
    }
  }, [selectedTicket]);

  function getHeader() {
    switch (headerType) {
      case 'customerOrderDetails': {
        const order = table.data.order;
        return (
          <tr className='order-row ship-info'>
            <td colSpan='6'>
              <span className='ship-info-wrapper'>
                <span>Carrier: {order.carrier}</span>
                <span>
                  Tracking #:
                  <span
                    role='button'
                    aria-label=''
                    tabIndex={0}
                    onClick={() => {
                      if (order.carrier.toLowerCase() === 'fedex ground' && order.trackingNum) {
                        window.open(
                          `https://www.fedex.com/fedextrack/?trknbr=${order.trackingNum}`
                        );
                      }
                    }}
                    className={`tracking ${
                      order.carrier.toLowerCase() === 'fedex ground' && order.trackingNum ?
                        'fedex'
                      : ''
                    }`}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        if (order.carrier.toLowerCase() === 'fedex ground' && order.trackingNum) {
                          window.open(
                            `https://www.fedex.com/fedextrack/?trknbr=${order.trackingNum}`
                          );
                        }
                      }
                    }}
                  >
                    {order.trackingNum || 'Unavailable'}
                  </span>
                </span>
                <span className='dest-span'>
                  Destination:
                  <span>
                    {order?.shipName?.toUpperCase()}
                    <br></br>
                    {order?.address?.lineOne?.toUpperCase()}
                    <br></br>
                    {/* {order?.address?.lineTwo?.toUpperCase()}
                                        {order?.address?.lineTwo ? <br></br> : ""} */}
                    {order?.address?.lineThree?.toUpperCase()}
                    {order?.address?.lineThree ?
                      <br></br>
                    : ''}
                    {order?.city?.toUpperCase()}, {order?.state} {order?.zipCode}
                  </span>
                </span>
                <span
                  role='button'
                  aria-label='Need Help?'
                  tabIndex={0}
                  className='support-button-span'
                  onClick={() =>
                    setMainTable({
                      name: `Order #${order.poNum}`,
                      type: 'customerOrderSupport',
                      data: { order: order },
                    })
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setMainTable({
                        name: `Order #${order.poNum}`,
                        type: 'customerOrderSupport',
                        data: { order: order },
                      });
                    }
                  }}
                >
                  Need help?
                  <BasicButton
                    text={'Get Support'}
                    type={'basic-green-button'}
                  />
                </span>
              </span>
            </td>
          </tr>
        );
      }
      case 'customerOrderSupport':
        return (
          <td colSpan='8'>
            <span
              className='order-support-header'
              id='order-support-header'
            >
              <input
                type='checkbox'
                className='checkbox select-table'
                onClick={() => {
                  setSelectedTable(!selectedTable);
                  setSelectedClaims([]);
                  setSelectedRows([]);
                }}
                checked={selectedTable}
              />
              <span>
                PO: <span className='order-header-info'>{table.data.order.poNum}</span>
              </span>
              <span>
                Order#: <span className='order-header-info'>{table.data.order.orderNum}</span>
              </span>
              <span>
                Date: <span className='order-header-info'>{table.data.order.orderDate}</span>
              </span>
              <span>
                Ship Date: <span className='order-header-info'>{table.data.order.shipDate}</span>
              </span>
              <span className='status-span'>
                Status:
                <TicketType
                  ticket={{
                    title: table.data.order.status,
                    color: table.data.order.statusColor,
                  }}
                />
              </span>
              <span className='dropdown-placeholder'>
                {selectedTable && (
                  <BasicDropdown
                    list={orderTicketTypes}
                    title={'How can we help you?'}
                    chosen={selectedTicket}
                    setChosen={setSelectedTicket}
                  />
                )}
              </span>
              {/* <span>Tracking Info: { }</span> */}
            </span>
          </td>
        );
      default:
        return (
          <tr className='order-head'>
            {columns.map((c, i) => (
              <th key={i}>
                <span className='column-title'>
                  {c.title}
                  {c.filterable ?
                    openFilter === c.title ?
                      <>
                        <FiChevronUp
                          onClick={() => {
                            setOpenFilter();
                          }}
                        />
                        <FilterOptions
                          filterBy={c.value}
                          filter={filter}
                          setFilter={setFilter}
                          setOpenFilter={setOpenFilter}
                          options={filterOptions[c.value]}
                        />
                      </>
                    : <FiChevronDown
                        onClick={() => {
                          setOpenFilter(c.title);
                        }}
                      />

                  : ''}
                </span>
              </th>
            ))}
          </tr>
        );
    }
  }

  return getHeader();
}

export default OrdersTableHeader;
