import React, { useEffect, useRef, useState } from 'react';

function FilterOptions({ filterBy, options, filter, setFilter, setOpenFilter }) {
  const componentRef = useRef(null);
  const [selectAll, setSelectAll] = useState();

  function handleSelect(option) {
    setSelectAll();
    if (Object.keys(filter).includes(filterBy)) {
      if (filter[filterBy]?.includes(option)) {
        if (filter[filterBy].length > 1) {
          setFilter({ ...filter, [filterBy]: filter[filterBy].filter((f) => f !== option) }); //remove option
        } else {
          const { [filterBy]: _, ...newState } = filter;
          setFilter(newState);
        }
      } else {
        setFilter({ ...filter, [filterBy]: [...filter[filterBy], option] });
      }
    } else {
      setFilter({ ...filter, [filterBy]: [option] });
    }
  }

  function handleSelectAll() {
    const { [filterBy]: _, ...newState } = filter;
    setFilter(newState);
    setSelectAll(!selectAll);
  }

  function handleClickOutside(event) {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setOpenFilter();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={componentRef}
      className={`filter-box ${options?.length ? '' : 'hide'}`}
    >
      <div className={`filter-option ${selectAll ? 'selected' : ''} select-all`}>
        <input
          type='checkbox'
          className='checkbox'
          checked={selectAll}
          onClick={handleSelectAll}
        />
        SELECT ALL
      </div>
      {options?.map((option, index) => (
        <div
          key={index}
          className={`filter-option ${(filter?.hasOwnProperty(filterBy) && filter[filterBy].includes(option)) || selectAll ? 'selected' : ''}`}
        >
          <input
            type='checkbox'
            className='checkbox'
            checked={
              (filter?.hasOwnProperty(filterBy) && filter[filterBy].includes(option)) || selectAll
            }
            onClick={() => handleSelect(option)}
          />
          {option}
        </div>
      ))}
    </div>
  );
}

export default FilterOptions;
