import React, { useEffect, useState } from 'react';
import ReusablePopup from '../ReusableElements/ReusablePopup';
import MissingPartsPopup from './MissingPartsPopup';
import TicketType from '../ReusableElements/TicketType';
import UploadFiles from '../Shared/UploadFiles';
import { MdKeyboardArrowDown } from 'react-icons/md';
import BasicDropdown from '../ReusableElements/BasicDropdown';
import '../../CSS/ClaimsPopup.css';
import useWebsiteAPI from '../../Api/websiteAPI';
import { IoCloseOutline } from 'react-icons/io5';
import { useCart } from '../../Context/CartContext';

function ClaimsPopup({
  claimPopup,
  setClaimPopup,
  selectedTable,
  selectedRows,
  allRows,
  orderTicketTypes,
  selectedClaims,
  setNotification,
}) {
  const websiteAPI = useWebsiteAPI();
  const { accountChosen } = useCart();
  const [claimDetails, setClaimDetails] = useState({});
  const [productParts, setProductParts] = useState([]);

  async function getProductParts() {
    await websiteAPI
      .post(`portalcontent/getProductParts`, { sku: allRows[claimPopup.num]?.sku })
      .then((response) => {
        setProductParts([...response.data, { title: 'other' }]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function addDetails(values) {
    let holderObj = { ...claimDetails };
    holderObj[claimPopup.num] = holderObj[claimPopup.num] || {};
    Object.entries(values).forEach(([key, value]) => {
      holderObj[claimPopup.num][key] = value;
    });
    setClaimDetails(holderObj);
  }

  function handleContinue() {
    console.log('selectedRows', selectedRows);
    if (!claimDetails[claimPopup.num]?.qty) {
      setNotification('Please select the quantity of products.');
    } else if (
      selectedClaims.find((i) => i.index === claimPopup.num).value !== 'Returns & Cancellations' &&
      selectedClaims.find((i) => i.index === claimPopup.num).value !== 'Something Else' &&
      productParts.length &&
      !claimDetails[claimPopup.num]?.parts?.length
    ) {
      setNotification(
        `Please select ${
          selectedClaims.find((i) => i.index === claimPopup.num).value === 'Missing Parts'
            ? 'missing'
            : 'damaged'
        } product parts.`
      );
    } else if (!claimDetails[claimPopup.num]?.comments?.length) {
      setNotification(
        'Please provide a detailed description of your claim, including all relevant facts and information.'
      );
    } else {
      if (claimPopup.num === selectedRows[selectedRows.length - 1]) {
        setClaimPopup();
        handleSubmit();
      } else {
        setClaimPopup(
          (selectedTable && allRows.length > claimPopup?.num + 1) ||
            selectedRows.length > selectedRows.indexOf(claimPopup?.num) + 1
            ? selectedTable
              ? {
                  num: claimPopup?.num + 1,
                }
              : {
                  num: selectedRows[selectedRows.indexOf(claimPopup?.num) + 1],
                }
            : false
        );
      }
    }
  }

  async function handleSubmit() {
    for (const claim of Object.keys(claimDetails)) {
      const formData = new FormData();
      if (claimDetails[claim]?.files?.length) {
        claimDetails[claim].files.forEach((file) => {
          formData.append('arrayOfFiles', file);
        });
      }
      formData.append('formValues', JSON.stringify(claimDetails[claim]));
      await websiteAPI
        .post(`portalcontent/submitClaim`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          console.log(response.status);
        })
        .catch((err) => {
          console.log(err);
          setNotification('Error submitting claim. Please try again later.');
        });
    }
  }

  useEffect(() => {
    getProductParts();
    addDetails({
      ticketType: orderTicketTypes?.find(
        (ticket) => ticket.title === selectedClaims?.find((i) => i.index === claimPopup.num)?.value
      ),
      sku: allRows[claimPopup.num]?.sku,
      orderNum: allRows[claimPopup.num]?.orderNum,
      dealer: accountChosen.name,
    });
  }, [claimPopup]);

  useEffect(() => {
    console.log('claimDetails', claimDetails);
  }, [claimDetails]);

  return (
    <ReusablePopup
      innerComponent={
        <div
          style={{ flexDirection: 'column', width: '600px' }}
          className='basic-flex claim-popup-inner-component'
        >
          <div className='popup-header-div'>
            <div className='left-side-popup-header'>
              <div className='popup-index-number'>{claimPopup.num + 1 || 1}</div>
              <div className='popup-important-detail'>
                {allRows[claimPopup.num]?.sku || 'MTFB12-MDPEPCGYGY'}
              </div>
            </div>
            <TicketType
              ticket={orderTicketTypes?.find(
                (ticket) =>
                  ticket.title === selectedClaims?.find((i) => i.index === claimPopup.num)?.value
              )}
            />
          </div>
          <div className='select-qty-div'>
            <h3 className='popup-h3'>Product Quantity</h3>
            <BasicDropdown
              list={Array.from({ length: allRows[claimPopup.num]?.qty }, (_, i) => {
                return { title: i + 1 };
              })}
              title='quantity'
              chosen={claimDetails[claimPopup.num]?.qty}
              setChosen={(chosen) => addDetails({ qty: chosen })}
            />
          </div>
          {selectedClaims.find((i) => i.index === claimPopup.num).value !==
            'Returns & Cancellations' &&
            productParts.length > 0 && (
              <div className='select-parts-div'>
                <h3 className='popup-h3'>
                  {selectedClaims.find((i) => i.index === claimPopup.num).value === 'Missing Parts'
                    ? 'Missing'
                    : selectedClaims.find((i) => i.index === claimPopup.num).value ===
                      'Something Else'
                    ? 'Select'
                    : 'Damaged'}{' '}
                  Parts
                </h3>
                <BasicDropdown
                  list={productParts}
                  title='selections'
                  chosen={claimDetails[claimPopup.num]?.parts || []}
                  setChosen={(chosen) => addDetails({ parts: chosen })}
                  selectMultiple={true}
                />
              </div>
            )}
          <div className='selected-product-part-wrapper'>
            {claimDetails[claimPopup.num]?.parts?.map((p) => (
              <span className='selected-product-part'>
                {p.title}{' '}
                <IoCloseOutline
                  onClick={() =>
                    addDetails({
                      parts: claimDetails[claimPopup.num].parts.filter((i) => i.id !== p.id),
                    })
                  }
                />
              </span>
            ))}
          </div>
          <h3 className='popup-h3'>Description</h3>
          <textarea
            className='popup-comments basic-input'
            placeholder='Please provide a detailed description of your claim, including all relevant facts and information.'
            value={claimDetails[claimPopup.num]?.comments || ''}
            onChange={(e) => addDetails({ comments: e.target.value })}
          ></textarea>
          <UploadFiles
            files={claimDetails[claimPopup.num]?.files || []}
            setFiles={(files) => addDetails({ files: files })}
          />
        </div>
      }
      data={selectedTable ? allRows?.map((i, index) => index) : selectedRows}
      open={claimPopup}
      setOpen={setClaimPopup}
      backAction={claimPopup.num > 0 ? () => setClaimPopup({ num: claimPopup.num - 1 }) : false}
      continueAction={() => handleContinue()}
    />
  );
}

export default ClaimsPopup;
