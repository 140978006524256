import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useWebsiteAPI from '../../Api/websiteAPI';
import { useCart } from '../../Context/CartContext';
import '../../CSS/NewOrderTracking.css';
import useAuth from '../../Hooks/useAuth';

function GetOrdersByAccount({ myOrders, setMyOrders, loading, setLoading }) {
  const { notificationData } = useCart();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { accountChosen, setAccountChosen, searchAccount, setSearchAccount } = useCart();
  //const [myOrders, setMyOrders] = useState([]);
  // const [loading, setLoading] = useState();
  const [customerList, setCustomerList] = useState();
  const [searchVal, setSearchVal] = useState('');
  const [showDrop, setShowDrop] = useState();
  // const [orderStatusCodes, setOrderStatusCodes] = useState();
  const websiteAPI = useWebsiteAPI();

  const getOrderHeaders = async () => {
    //if auth.roles is a dealer, get their orderHeaders
    if (
      auth?.roles.includes(3500) ||
      auth?.accounts?.find((i) => i.last_login === 1)?.AccountRoles?.includes(1400)
    ) {
      setLoading(true);
      setSearchAccount(false);

      await websiteAPI
        .post(`orders/orderHeaders`, {
          account: accountChosen ? accountChosen.customerId : false,
        })
        .then((response) => {
          setLoading(false);
          getOrderStatus(response.data);
          //setMyOrders(response.data);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
  };
  async function getOrderStatus(orders) {
    await websiteAPI
      .get(`orders/getOrderStatus`)
      .then((response) => {
        setMyOrders(
          orders.map((order) => {
            return {
              ...order,
              status: response.data.find((c) => c.code === order?.invoiceNum)?.status,
              statusColor: response.data.find((c) => c.code === order?.invoiceNum)?.color,
            };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const changeAccounts = async () => {
    setMyOrders([]);
    setSearchAccount(true);
    setAccountChosen();
  };

  useEffect(() => {
    if (notificationData?.some((i) => i.Path === '/track-order')) {
      navigate('/unauthorized');
    }
  }, []);

  useEffect(() => {
    websiteAPI
      .post(`adminPortal/getCustomers`, {
        search: '',
        active: 'A',
        limit: 99999,
      })
      .then((response) => {
        setCustomerList(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (auth?.roles?.includes(3500) && accountChosen && searchAccount === false) {
      getOrderHeaders();
    } else if (auth?.roles.includes(3500)) {
      setMyOrders([]);
      setSearchAccount(true);
    } else {
      getOrderHeaders();
    }
  }, []);

  return (
    <>
      <div className='track-order'>
        <div>
          {auth?.roles?.includes(3500) ?
            <div
              className='get-orders-for-account'
              style={{
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                marginBottom:
                  (
                    myOrders?.length > 0 ||
                    loading ||
                    (myOrders?.length <= 0 && !loading && accountChosen && !searchAccount)
                  ) ?
                    '20px'
                  : '18rem',
                background: 'var(--lightgrey-30)',
                width: '100%',
                justifyContent: 'center',
                position: 'relative',
                minHeight: '75px',
              }}
            >
              {accountChosen && !searchAccount ?
                <div
                  style={{ minWidth: '443px', textAlign: 'center' }}
                  className='no-'
                >
                  Orders for {accountChosen.name} - Account #{accountChosen.customerId}
                </div>
              : <div
                  style={{ width: '443px', height: '35px' }}
                  className='search-orders'
                >
                  <input
                    style={{ width: '100%' }}
                    type='text'
                    name='searchVal'
                    placeholder='Search Account'
                    id='searchVal'
                    value={searchVal}
                    onChange={(e) => {
                      setSearchVal(e.target.value);
                    }}
                    onFocus={() => {
                      if (accountChosen) {
                        setSearchVal('');
                      }
                      setShowDrop(true);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        // e.preventDefault();

                        const findSearchVal = customerList?.filter((i) => {
                          return (
                            i.name?.toString()?.toUpperCase() ===
                            searchVal?.toString()?.toUpperCase()
                          );
                        });
                        if (findSearchVal) {
                          setAccountChosen(findSearchVal[0]);
                          setShowDrop(false);
                        }
                      }
                      // if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                      //   if (showDrop) {
                      //     setIndexFocus(indexFocus + 1);
                      //   }
                      // }
                    }}
                    required
                  ></input>

                  {customerList && searchVal && showDrop ?
                    <div
                      style={{ top: '4rem', left: '21.7rem' }}
                      className='modelNum-drop-div'
                    >
                      <div className='modelNum-dropdown'>
                        {customerList
                          ?.filter((i) => {
                            const searchUpperCase = searchVal?.toString()?.toUpperCase();
                            const nameUpperCase = i.name?.toString()?.toUpperCase();
                            const customerIdUpperCase = i.customerId?.toString()?.toUpperCase();

                            return (
                              (customerIdUpperCase && customerIdUpperCase === searchUpperCase) ||
                              (customerIdUpperCase &&
                                customerIdUpperCase.includes(searchUpperCase)) ||
                              (nameUpperCase && nameUpperCase.includes(searchUpperCase))
                            );
                          })
                          ?.sort((a, b) => {
                            const searchUpperCase = searchVal?.toString()?.toUpperCase();
                            const nameUpperCaseA = a.name?.toString()?.toUpperCase();
                            const customerIdUpperCaseA = a.customerId?.toString()?.toUpperCase();
                            const nameUpperCaseB = b.name?.toString()?.toUpperCase();
                            const customerIdUpperCaseB = b.customerId?.toString()?.toUpperCase();

                            const isExactMatchA = customerIdUpperCaseA === searchUpperCase;
                            const isExactMatchB = customerIdUpperCaseB === searchUpperCase;

                            if (isExactMatchA && !isExactMatchB) {
                              return -1;
                            } else if (!isExactMatchA && isExactMatchB) {
                              return 1;
                            }

                            // If neither or both are exact matches, sort by proximity
                            const proximityA =
                              (isExactMatchA ? 0 : (
                                customerIdUpperCaseA?.indexOf(searchUpperCase)
                              )) ||
                              (nameUpperCaseA && nameUpperCaseA.indexOf(searchUpperCase)) ||
                              9999;

                            const proximityB =
                              (isExactMatchB ? 0 : (
                                customerIdUpperCaseB?.indexOf(searchUpperCase)
                              )) ||
                              (nameUpperCaseB && nameUpperCaseB.indexOf(searchUpperCase)) ||
                              9999;

                            return proximityA - proximityB;
                          })
                          ?.map((i, index) => {
                            return (
                              <div
                                role='button'
                                tabIndex={0}
                                key={index}
                                className={`modelNum-list-item`}
                                onClick={() => {
                                  setAccountChosen(i);
                                  setSearchVal(`${i.customerId} - ${i.name}`);
                                  setShowDrop(false);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    setAccountChosen(i);
                                    setSearchVal(`${i.customerId} - ${i.name}`);
                                    setShowDrop(false);
                                  }
                                }}
                              >
                                {i.customerId} - {i.name}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  : ''}
                </div>
              }
              {auth?.roles.includes(3500) && searchAccount ?
                <button
                  onClick={() => {
                    getOrderHeaders();
                    setSearchVal('');
                  }}
                  className='editableFormButton'
                  disabled={!accountChosen}
                >
                  Get Orders for Account
                </button>
              : auth?.roles.includes(3500) ?
                <button
                  onClick={changeAccounts}
                  className='editableFormButton'
                >
                  Change Account
                </button>
              : ''}
            </div>
          : ''}
        </div>
      </div>
    </>
  );
}

export default GetOrdersByAccount;
