import React, { useEffect, useState } from 'react';
import '../../CSS/AnnualBalance.css';
import useWebsiteAPI from '../../Api/websiteAPI';

const AnnualBalance = () => {
  const [orders, setOrders] = useState([]);
  const websiteAPI = useWebsiteAPI();
  useEffect(() => {
    getAnnualBalance();
  }, []);

  useEffect(() => {
    if (orders && orders.length === 12) {
      const grid = document.querySelector('.year-balance');
      const newOrders = orders.slice(0, -1);
      // Remove existing dots and lines
      grid.innerHTML = '';

      // Create dots
      newOrders.forEach((order, index) => {
        const dot = document.createElement('div');
        dot.classList.add('dot');
        dot.style.gridColumn = `${index + 1}`;
        dot.style.gridRow = `${12 - Math.ceil(order.amount / 10)}`;
        dot.setAttribute('data-orders', order.amount); // Set data attribute with the number of orders
        dot.addEventListener('mouseover', handleDotHover); // Add mouseover event listener
        dot.addEventListener('mouseout', handleDotMouseOut); // Add mouseout event listener
        grid.appendChild(dot);
      });

      newOrders.forEach((order, index) => {
        const monthLabel = document.createElement('div');
        monthLabel.classList.add('balance-month');
        monthLabel.textContent = order.month;
        monthLabel.style.gridColumn = `${index + 1}`;
        monthLabel.style.gridRow = `${12 - Math.ceil(order.amount / 10) + 1}`; // Aligns text directly under the dot
        grid.appendChild(monthLabel);
      });

      for (let i = 0; i < newOrders.length - 1; i++) {
        const dot1 = grid.querySelector(`.dot:nth-child(${i + 1})`);
        const dot2 = grid.querySelector(`.dot:nth-child(${i + 2})`);

        if (dot1 && dot2) {
          const line = document.createElement('div');
          line.classList.add('balance-line');

          // Calculate the center points of dot1 and dot2
          const x1 = dot1.offsetLeft + dot1.offsetWidth / 2;
          const y1 = dot1.offsetTop + dot1.offsetHeight / 2;
          const x2 = dot2.offsetLeft + dot2.offsetWidth / 2;
          const y2 = dot2.offsetTop + dot2.offsetHeight / 2;

          // Set line position and rotation
          line.style.left = `${x1}px`;
          line.style.top = `${y1}px`;
          line.style.width = `${Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2)}px`; // Distance between dots
          line.style.transformOrigin = 'left';
          line.style.transform = `rotate(${Math.atan2(y2 - y1, x2 - x1)}rad)`;

          grid.appendChild(line);
        }
      }
    }
  }, [orders]);

  // Mouseover event handler for dots
  const handleDotHover = (event) => {
    const dot = event.target;
    const orders = dot.getAttribute('data-orders');
    const tooltip = document.createElement('div');
    tooltip.classList.add('tooltip');
    tooltip.textContent = `${orders} Orders`;
    document.body.appendChild(tooltip);

    // Position tooltip relative to the dot
    const rect = dot.getBoundingClientRect();
    tooltip.style.top = `${rect.bottom}px`;
    tooltip.style.left = `${rect.left + dot.offsetWidth / 2 - tooltip.offsetWidth / 2}px`;

    // Remove the tooltip when mouse moves out of the dot
    dot.addEventListener('mouseout', () => {
      tooltip.remove();
    });
  };

  // Mouseout event handler for dots
  const handleDotMouseOut = () => {
    const tooltip = document.querySelector('.tooltip');
    if (tooltip) {
      tooltip.remove();
    }
  };

  async function getAnnualBalance() {
    await websiteAPI
      .get(`portalcontent/getannualbalance`)
      .then((response) => {
        setOrders(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return orders && orders.length === 12 ?
      <div className='balance-div'>
        <div className='annual-balance-text'>Your Annual Orders</div>
        <div className='year-balance'></div>

        <div className='percent-text'>
          +
          {Math.round(
            (orders[0].amount / orders.reduce((total, current) => total + current.amount, 0)) * 100
          )}
          % this month
        </div>
      </div>
    : <></>;
};

export default AnnualBalance;
