import { useState } from 'react';
import { IoIosArrowDropup } from 'react-icons/io';
import { useSelector } from 'react-redux';
import HeaderNavLinks from '../../Components/Layout/HeaderNavLinks';
import ReusablePopup from '../../Components/ReusableElements/ReusablePopup';
import '../../CSS/HeaderNav.css';
import '../../CSS/Mobile/M_HeaderNav.css';

const PortalHeaderNav = () => {
  const mobileScreen = useSelector((state) => state.isMobile.value);
  const [hiddenPortal, setHiddenPortal] = useState(true);
  const [warningPopup, setWarningPopup] = useState(false);
  const [link, setLink] = useState();

  return hiddenPortal ?
      <>
        <div className='portal-header-fixed'>
          <div className='thin-nav-bar'>
            <div
              role='button'
              aria-label='Expand the main website navigation'
              tabIndex={0}
              className='hidden-nav-bar'
              onClick={() => setHiddenPortal(false)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setHiddenPortal(false);
                }
              }}
            ></div>
            <div className='expand-nav-text'>Expand the main website navigation</div>
          </div>
        </div>
        <div style={{ height: '39px', background: 'white' }}></div>
      </>
    : <>
        <div className='portal-header-fixed'>
          <nav className='portal-header-nav header-nav padding'>
            <ul
              style={{ width: '66rem' }}
              className='header-nav-list-items'
            >
              {!mobileScreen.isMobile ?
                <HeaderNavLinks
                  setWarningPopup={setWarningPopup}
                  setLink={setLink}
                  portal={true}
                />
              : ''}
              <IoIosArrowDropup
                aria-label='Close the main website navigation'
                className='circle-arrow-up'
                onClick={() => setHiddenPortal(true)}
              />
            </ul>
          </nav>
        </div>
        <div
          style={{
            height: '130px',
            background: 'white',
          }}
        ></div>
        {warningPopup && (
          <ReusablePopup
            innerComponent={
              <div className='leave-portal-popup'>
                <h2 className='leave-portal-title'>Do you want to leave the NPS Portal?</h2>
                <div className='leave-portal-text'>
                  If you want to go to the main page, click continue.
                </div>
              </div>
            }
            open={warningPopup}
            setOpen={setWarningPopup}
            continueAction={() =>
              (window.location.href = `${process.env.REACT_APP_MAIN_WEBSITE}${link}`)
            }
          />
        )}
      </>;
};

export default PortalHeaderNav;
