import React from 'react';
import '../../CSS/Tile.css';
import BasicButton from '../ReusableElements/basicButton';
import { useNavigate } from 'react-router-dom';

function Tile({ tile }) {
  const navigate = useNavigate();

  // Determine if the tile should be centered or split
  const centerTile = location.pathname.includes('/users') && !tile.page;
  const splitLink = tile.links.length > 5 && !tile.page;
  const length = tile.links.length;
  const splitPoint = Math.floor(length / 2);

  function buttonClicked(i) {
    switch (i.link_type) {
      case 'internal':
        navigate(`/users${i.link}`, { state: { childLinks: i.sub_links } });
        break;
      case 'external':
        window.open(i.link);
        break;
      case 'nps_website':
        window.open(`${process.env.REACT_APP_MAIN_WEBSITE}${i.link}`);
        break;
    }
  }

  return (
    <div className={`tile-div ${splitLink ? 'splitLinks' : ''} ${centerTile ? 'center' : ''}`}>
      <div className='tile-left'>
        <img
          className='tile-img'
          src={tile?.image}
          alt={tile?.title}
        />
        <div className='tile-title'>{tile?.title}</div>
      </div>
      <div className='tile-middle' />
      <div className='tile-right'>
        {tile.links.slice(0, splitLink ? splitPoint : length)?.map((i, index) => {
          return (
            <BasicButton
              key={index}
              text={i?.text}
              type='green-outlined-button'
              onClick={() => buttonClicked(i)}
            />
          );
        })}
      </div>
      {splitLink && (
        <div className='tile-right'>
          {tile.links.slice(splitPoint)?.map((i, index) => {
            return (
              <BasicButton
                key={index}
                text={i?.text}
                type='green-outlined-button'
                onClick={() => buttonClicked(i)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Tile;
