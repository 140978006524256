import { configureStore } from '@reduxjs/toolkit';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './Context/AuthProvider';
import { CartProvider } from './Context/CartContext';
import { UserProvider } from './Context/UserProvider';
import isMobileReducer from './ReduxSlices/isMobile';
import productStateReducer from './ReduxSlices/productState';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

const store = configureStore({
  reducer: {
    isMobile: isMobileReducer,
    productState: productStateReducer,
  },
});

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <AuthProvider>
        <UserProvider>
          <CartProvider>
            <App />
          </CartProvider>
        </UserProvider>
      </AuthProvider>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console. log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
