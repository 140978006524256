import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../CSS/CycleCalendar.css';
import '../../CSS/NPSCalendarEvents.css';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import ReusablePopup from '../../Components/ReusableElements/ReusablePopup';
import { useState } from 'react';

const NPSCalendarEvents = () => {
  const localizer = momentLocalizer(moment);
  const events = [
    {
      title: 'Closed for Passover',
      start: '2024-04-23T09:00:00-00:00',
      end: '2024-04-30T09:00:00-00:00',
      allDay: true,
    },
  ];
  const [eventPopup, setEventPopup] = useState();

  return (
    <div
      style={{ marginLeft: '2rem', height: '340px', width: '400px' }}
      className='nps-calendar-div orders-of-month-div'
    >
      <div
        style={{ padding: '0px' }}
        className='annual-balance-text'
      >
        NPS April Events
      </div>
      <Calendar
        localizer={localizer}
        views={['month']}
        style={{ width: '100%' }}
        toolbar={false}
        events={events ? events : []}
        onSelectEvent={(e) => setEventPopup(e)}
      />
      {eventPopup && (
        <ReusablePopup
          title={'Closed for Passover'}
          innerComponent={
            <div>
              We will be closing at 1pm on Monday, April 22nd, and will be reopening on Wednesday,
              May 1st at 8am. For your convenience, we will be open from 8am to 6pm for the
              following week. If you have any further questions, feel free to reach out to our
              support team.
            </div>
          }
          open={eventPopup}
          setOpen={setEventPopup}
        />
      )}
    </div>
  );
};

export default NPSCalendarEvents;
