import { useEffect, useState } from 'react';
import { ImArrowUp2 } from 'react-icons/im';
import { Outlet } from 'react-router-dom';
import useWebsiteAPI from '../Api/websiteAPI';
import PortalHeader from '../Components/Layout/PortalHeader';
import PortalHeaderNav from '../Components/Layout/PortalHeaderNav';
import PortalNavBar from '../Components/Layout/PortalNavBar';
import DealerLogin from '../Components/Login/DealerLogin';
import LoginButton from '../Components/ReusableElements/LoginButton';
import '../CSS/Layout.css';
import useAuth from '../Hooks/useAuth';
import useLogout from '../Hooks/useLogout';
import useUserInfo from '../Hooks/useUserInfo';

// const contact =
//   'https://res.cloudinary.com/da3rom333/image/upload/v1688653574/Website%20Assets/SVGs/Contact_Us_xe7p7t.svg';
// const dealer =
//   'https://res.cloudinary.com/da3rom333/image/upload/v1688653575/Website%20Assets/SVGs/Dealer_Portal_zo9epu.svg';
// const track =
//   'https://res.cloudinary.com/da3rom333/image/upload/v1688653565/Website%20Assets/SVGs/Track_Your_Order_cjd5zm.svg';

const PortalLayout = () => {
  const [tabs, setTabs] = useState();
  const [clicked, setClicked] = useState();
  const { displayLoginBox } = useUserInfo();
  const { auth } = useAuth();
  const logout = useLogout();
  const websiteAPI = useWebsiteAPI();

  useEffect(() => {
    getTabs();
  }, []);

  async function getTabs() {
    websiteAPI
      .get(`portalContent/getNavTabs`)
      .then((response) => {
        setTabs(response.data);
        setClicked(response.data[0]);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <div className='content'>
      <div className='grid'>
        <div className='grid-paddding-outer'></div>
      </div>

      <PortalHeaderNav />
      <PortalHeader />
      <PortalNavBar
        tabs={tabs}
        clicked={clicked}
        setClicked={setClicked}
      />

      <LoginButton
        className='callUsButton'
        displayLoginBox={displayLoginBox}
        loggedIn={auth?.rep}
        name={auth?.rep?.substring(0, auth?.rep?.indexOf(' ')) || auth?.rep}
        logOut={logout}
      />
      <DealerLogin />
      {auth?.rep && (
        <div>
          <Outlet />
        </div>
      )}
      <div className='arrowContact'>
        <div
          role='button'
          aria-label='Go to the Top of the Page'
          tabIndex={0}
          className='arrow'
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }
          }}
        >
          <ImArrowUp2 />
        </div>
      </div>
    </div>
  );
};

export default PortalLayout;
