import React from 'react';

function ParentTitle({ currentParentTitle, parentTitles, changeParent }) {
  return (
    <div className='parent-title'>
      {parentTitles?.map((i, index) => (
        <div
          key={index}
          role='button'
          aria-label={i}
          tabIndex={0}
          onClick={() => changeParent(i)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              changeParent(i);
            }
          }}
          style={{ cursor: 'pointer' }}
          className={`${currentParentTitle === i ? 'selected-parent' : ''}`}
        >
          {i}
        </div>
      ))}
    </div>
  );
}

export default ParentTitle;
