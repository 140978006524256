import React, { useEffect, useState } from 'react';
import useWebsiteAPI from '../../Api/websiteAPI';
import '../../CSS/MonthOrders.css';

const MonthOrders = () => {
  const [orders, setOrders] = useState([]);
  const websiteAPI = useWebsiteAPI();

  useEffect(() => {
    getMonthOrders();
  }, []);

  async function getMonthOrders() {
    await websiteAPI
      .get(`portalcontent/getMonthOrders`)
      .then((response) => {
        setOrders(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return orders && orders.length > 0 ?
      <div className='orders-of-month-div'>
        <div
          style={{ padding: '0px' }}
          className='annual-balance-text'
        >
          Items ordered this month
        </div>
        <div className='amount-div'>
          <div className='orders-amount'>
            {orders.reduce((total, current) => total + current.amount, 0)}
          </div>
          <div className='items-text'>Items</div>
        </div>
        <div className='percent-text'>
          {Math.round(
            (orders[orders.length - 1].amount /
              orders.reduce((total, current) => total + current.amount, 0)) *
              100
          )}
          % this week
        </div>
      </div>
    : <></>;
};

export default MonthOrders;
