import { AdvancedImage } from '@cloudinary/react';
import React, { useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { createSearchParams } from 'react-router-dom';
import useWebsiteAPI from '../../Api/websiteAPI';
import { useCart } from '../../Context/CartContext';
import useAuth from '../../Hooks/useAuth';
import BasicDropdown from '../ReusableElements/BasicDropdown';
import TicketType from '../ReusableElements/TicketType';
import FormatImageLink from '../Shared/FormatImageLink';

function OrdersTableData({
  rowIndex,
  row,
  column,
  openRow,
  handleOpenRow,
  selectedRows,
  setSelectedRows,
  selectedTable,
  setSelectedTable,
  orderTicketTypes,
  selectedClaims,
  setSelectedClaims,
  statusIcons,
}) {
  const { notificationData } = useCart();
  const { auth } = useAuth();
  const decodeAndFormat = FormatImageLink();
  const websiteAPI = useWebsiteAPI();
  const [selectedTicket, setSelectedTicket] = useState();

  function handleCheckboxClick(index) {
    if (selectedTable) {
      setSelectedTable(false);
      setSelectedRows([index]);
      setSelectedClaims([]);
    } else if (selectedRows.includes(index)) {
      setSelectedRows((selected) => selected.filter((i) => i !== index));
      const filteredClaims = selectedClaims.filter((item) => item.index !== index);
      setSelectedClaims(filteredClaims);
    } else {
      setSelectedRows((selected) => {
        return [...selected, index].sort((a, b) => a - b);
      });
    }
  }

  async function handleProductClick() {
    if (column.value === 'imageLink' || column.value === 'sku') {
      const series = encodeURIComponent(row?.series?.config_group || row?.series?.series);
      const configs = row?.configOptions ? createSearchParams(row?.configOptions) : '';
      window.open(`https://www.nationalpublicseating.com/products/${series}?${configs}`);
    }
  }

  useEffect(() => {
    if (selectedTicket) {
      const updatedRows = [...selectedClaims];
      const claim = updatedRows.find((i) => i.index === rowIndex);
      if (claim) {
        const index = updatedRows.indexOf(claim);
        updatedRows[index] = {
          index: rowIndex,
          value: selectedTicket.title,
        };
        setSelectedClaims(updatedRows);
      } else {
        updatedRows.push({
          index: rowIndex,
          value: selectedTicket.title,
        });
        setSelectedClaims(updatedRows);
      }
    }
  }, [selectedTicket]);

  function getCellData() {
    switch (column.type) {
      case 'ticket':
        return <TicketType ticket={{ title: row.status, color: row.statusColor }} />;
      case 'expand':
        return openRow === row.orderNum ?
            <FiChevronUp
              className='open'
              onClick={() => {
                handleOpenRow();
              }}
            />
          : <FiChevronDown
              className='open'
              onClick={() => {
                handleOpenRow(row.orderNum);
              }}
            />;
      case 'image':
        return (
          <span className='image-div'>
            <img
              role='button'
              tabIndex={0}
              src={row[column.value]}
              onClick={() => handleProductClick(row)}
              alt={row.sku}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleProductClick(row);
                }
              }}></img>
            {row.productStatus !== 'A' && statusIcons && (
              <AdvancedImage
                style={{
                  position: 'absolute',
                  marginTop: '-11px',
                  marginLeft: '-58px',
                  cursor: 'pointer',
                }}
                cldImg={decodeAndFormat(
                  statusIcons?.find((j) => j.status === row.productStatus)?.icon_link
                )}
              />
            )}
          </span>
        );
      case 'inlineTitle':
        return (
          <span
            role='button'
            aria-label={row[column.value]}
            tabIndex={0}
            onClick={handleProductClick}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleProductClick();
              }
            }}>
            {column.title}
            <span className={`bold ${column.value}`}>
              {Math.round(row[column.value]) ? Math.round(row[column.value]) : row[column.value]}
            </span>
          </span>
        );
      case 'price':
        return (
            auth?.accounts
              .find((account) => account.last_login === 1)
              ?.AccountRoles?.some((role) => [1100, 1200, 1300].includes(role)) &&
              !notificationData?.some((i) => i.Path === 'pricing')
          ) ?
            <span className='unit-price-div'>
              UNIT PRICE:
              <span className='bold test'>
                {Number(row.unitPrice).toLocaleString(undefined, {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </span>
          : null;
      case 'totalPrice':
        return (
            auth?.accounts
              .find((account) => account.last_login === 1)
              ?.AccountRoles?.some((role) => [1100, 1200, 1300].includes(role)) &&
              !notificationData?.some((i) => i.Path === 'pricing')
          ) ?
            <span className='total-amount-div'>
              AMOUNT:
              <span className='bold'>
                {Number(row.unitPrice * row.qty).toLocaleString(undefined, {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </span>
          : null;
      case 'select':
        return (
          <input
            type='checkbox'
            className='checkbox select-row'
            checked={selectedRows.includes(rowIndex) || selectedTable}
            onClick={() => handleCheckboxClick(rowIndex)}
          />
        );
      case 'dropdown':
        return selectedRows.includes(rowIndex) && !selectedTable ?
            <BasicDropdown
              list={orderTicketTypes}
              title={'How can we help you?'}
              chosen={selectedTicket}
              setChosen={setSelectedTicket}
            />
          : <div className='dropdown-placeholder'></div>;
      default:
        return row[column.value];
    }
  }

  return getCellData() || '';
}

export default OrdersTableData;
