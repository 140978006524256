import React, { useEffect, useState } from 'react';
import HeaderWithActions from '../TileLayout/HeaderWithActions';
import { BiArrowBack } from 'react-icons/bi';
import '../../CSS/PortalIcons.css';
import { useNavigate } from 'react-router-dom';
import useWebsiteAPI from '../../Api/websiteAPI';
import { Cloudinary } from '@cloudinary/url-gen';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { AdvancedImage } from '@cloudinary/react';
import { CgSoftwareDownload } from 'react-icons/cg';
import { AiOutlineLink } from 'react-icons/ai';
import clipboardCopy from 'clipboard-copy';
import '../../CSS/MediaDownloads.css';

function PortalIcons() {
  const navigate = useNavigate();
  const websiteAPI = useWebsiteAPI();
  const [icons, setIcons] = useState();
  const [copied, setCopied] = useState(false);
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'da3rom333',
    },
  });

  function getData() {
    websiteAPI
      .get(`cloudinary/getAllIcons`)
      .then((response) => {
        setIcons(response.data.resources);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 400);
  }, [copied]);

  return (
    <div className='tile-layout'>
      <HeaderWithActions text={'NPS Users'} />
      <div
        role='button'
        aria-label='Go Back'
        tabIndex={0}
        className='portal-icon-back'
        onClick={() => navigate('/users')}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            navigate('/users');
          }
        }}
      >
        <BiArrowBack />
        BACK
      </div>
      <div className='portal-icon-header'>All Icons</div>
      <div className='icon-box'>
        {icons?.map((i, index) => {
          return (
            <div
              key={index}
              className='mediaTile'
            >
              <div className='tile-img'>
                <AdvancedImage cldImg={cld.image(i.public_id).resize(scale().width(250))} />
              </div>

              <div className='mediaTile-buttons'>
                <div
                  role='button'
                  aria-label='Download'
                  tabIndex={0}
                  className='download-img'
                  onClick={() => {
                    window.open(
                      i.secure_url
                        .replace('svg', 'jpg')
                        .replace('/upload', '/upload/fl_attachment'),
                      '_blank'
                    );
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      window.open(
                        i.secure_url
                          .replace('svg', 'jpg')
                          .replace('/upload', '/upload/fl_attachment'),
                        '_blank'
                      );
                    }
                  }}
                >
                  <CgSoftwareDownload />
                </div>
                <div
                  role='button'
                  aria-label='Copy Link'
                  tabIndex={0}
                  className='download-img'
                  onClick={() => {
                    setCopied(true);
                    clipboardCopy(i.secure_url.replace('svg', 'jpg'));
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setCopied(true);
                    }
                  }}
                >
                  <AiOutlineLink />
                  {copied ?
                    <div
                      className='notice'
                      style={{ left: '37.5%' }}
                    >
                      Link Copied
                    </div>
                  : ''}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PortalIcons;
