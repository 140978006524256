import React, { useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
const Paginator = ({ currentPage, totalPages, setCurrentPage, show, setShow }) => {
  const [jumpPage, setJumpPage] = useState();
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });

    const element = document.getElementById('in-stock-items');
    if (element) {
      element.scrollTo({
        top: 0,
        behavior: 'auto',
      });
    }
  }
  return (
    <div
      className='flex-page'
      style={{ justifyContent: 'space-between', width: '100%' }}
    >
      <div className='flex-page'>
        <div
          role='button'
          aria-label='Previous'
          tabIndex={0}
          onClick={() => {
            if (currentPage !== 0) {
              setCurrentPage(currentPage - 1);
              scrollToTop();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (currentPage !== 0) {
                setCurrentPage(currentPage - 1);
                scrollToTop();
              }
            }
          }}
          style={{
            color: currentPage === 0 ? '#acacac' : '#808080',
            cursor: currentPage === 0 ? 'default' : 'pointer',
            marginRight: '10px',
          }}
          className='navigate-page'
        >
          <IoIosArrowBack />
          Previous
        </div>
        <div style={{ cursor: 'default' }}>
          Page {currentPage + 1} of {totalPages}
        </div>
        <div
          role='button'
          aria-label='Next'
          tabIndex={0}
          onClick={() => {
            if (currentPage !== totalPages - 1) {
              setCurrentPage(currentPage + 1);
              scrollToTop();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (currentPage !== totalPages - 1) {
                setCurrentPage(currentPage + 1);
                scrollToTop();
              }
            }
          }}
          style={{
            color: currentPage === totalPages - 1 ? '#acacac' : '#808080',
            cursor: currentPage === totalPages - 1 ? 'default' : 'pointer',
            marginLeft: '10px',
          }}
          className='navigate-page'
        >
          Next
          <IoIosArrowForward />
        </div>
      </div>
      <div className='setshowqty'>
        Show
        <div
          role='button'
          aria-label='Show 16 on a Page'
          tabIndex={0}
          className={`${show === 16 ? 'current' : ''}`}
          onClick={() => {
            setShow(16);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setShow(16);
            }
          }}
        >
          16
        </div>
        <div
          role='button'
          aria-label='Show 32 on a Page'
          tabIndex={0}
          className={`${show === 32 ? 'current' : ''}`}
          onClick={() => {
            setShow(32);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setShow(32);
            }
          }}
        >
          32
        </div>
        <div
          role='button'
          aria-label='Show 64 on a Page'
          tabIndex={0}
          className={`${show === 64 ? 'current' : ''}`}
          onClick={() => {
            setShow(64);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setShow(64);
            }
          }}
        >
          64
        </div>
      </div>
      <div className='flex-page'>
        <div
          role='button'
          aria-label='Jump to Page'
          tabIndex={0}
          style={{ cursor: 'default' }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (jumpPage > 0 && jumpPage <= totalPages) {
                setCurrentPage(jumpPage - 1);
                scrollToTop();
                setJumpPage('');
              }
            }
          }}
        >
          Jump to page
          <input
            value={jumpPage}
            onChange={(e) => {
              setJumpPage(e.target.value);
            }}
          ></input>
        </div>
        <div
          role='button'
          aria-label='Go'
          tabIndex={0}
          className='navigate-page'
          style={{
            color: jumpPage > 0 && jumpPage <= totalPages ? '#808080' : '#acacac',
            cursor: jumpPage > 0 && jumpPage <= totalPages ? 'pointer' : 'default',
            marginLeft: '10px',
          }}
          onClick={() => {
            if (jumpPage > 0 && jumpPage <= totalPages) {
              setCurrentPage(jumpPage - 1);
              scrollToTop();
              setJumpPage('');
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (jumpPage > 0 && jumpPage <= totalPages) {
                setCurrentPage(jumpPage - 1);
                scrollToTop();
                setJumpPage('');
              }
            }
          }}
        >
          Go
          <IoIosArrowForward />
        </div>
      </div>
    </div>
  );
};

export default Paginator;
