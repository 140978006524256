import React, { useEffect, useState } from 'react';
import HeaderWithActions from '../Components/TileLayout/HeaderWithActions';
import useWebsiteAPI from '../Api/websiteAPI';
import '../CSS/TileLayout.css';
import Tile from '../Components/TileLayout/Tile';
import ParentTitle from '../Components/TileLayout/ParentTitle';

function TileLayout({ title }) {
  const websiteAPI = useWebsiteAPI();
  const [tileLabels, setTileLabels] = useState([]);
  const [parentTitles, setParentTitles] = useState([]);
  const [currentParentTitle, setCurrentParentTitle] = useState('');

  const tilesToDisplay =
    title === 'NpsUsers' ?
      tileLabels.filter((tile) => tile.page === currentParentTitle)
    : tileLabels;

  //calculation for page layout
  const length = tilesToDisplay.length;
  const isEven = length % 2 === 0 || length === 1;
  const splitPoint = length - 3;

  function changeParent(changeOption) {
    if (changeOption !== currentParentTitle) {
      setCurrentParentTitle(changeOption);
    }
  }

  function getTileInfo() {
    websiteAPI
      .get(`portalContent/get${title}Tiles`)
      .then((response) => {
        setTileLabels(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getTileInfo();
    document.title = `NPS Portal- ${title.replace(/^Nps/, 'NPS ')}`;
  }, [title]);

  useEffect(() => {
    const uniqueParentTitles = [...new Set(tileLabels.map((tile) => tile.page))];
    setParentTitles(uniqueParentTitles);
    setCurrentParentTitle(uniqueParentTitles[0] || '');
  }, [tileLabels]);

  return (
    <div className='tile-layout'>
      <HeaderWithActions text={title.replace(/^Nps/, 'NPS ')} />
      {title === 'NpsUsers' && (
        <ParentTitle
          currentParentTitle={currentParentTitle}
          parentTitles={parentTitles}
          changeParent={changeParent}
        />
      )}
      <div className={`tile-info-div ${title === 'NpsUsers' ? '' : 'header-margin'}`}>
        {tilesToDisplay.slice(0, isEven ? length : splitPoint).map((tile, index) => (
          <Tile
            key={index}
            tile={tile}
          />
        ))}
      </div>
      {!isEven && (
        <div className='tile-info-div odd'>
          {tilesToDisplay.slice(splitPoint).map((tile, index) => (
            <Tile
              key={index}
              tile={tile}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default TileLayout;
