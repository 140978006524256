import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../CSS/PortalNavBar.css';

const PortalNavBar = ({ tabs, clicked, setClicked }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (clicked) {
      navigate(clicked?.link);
    }
  }, [clicked]);

  useEffect(() => {
    setClicked(tabs?.find((tab) => tab.link === location.pathname));
  }, [location.pathname]);

  return (
    <div className='portal-side-navbar'>
      {tabs?.map((tab, index) => (
        <div
          role='button'
          aria-label={`Go to ${tab.title}`}
          tabIndex={0}
          key={index}
          onClick={() => {
            setClicked(tab);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setClicked(tab);
            }
          }}
          className={`${location.pathname === tab.link ? 'nav-item-clicked' : ''} side-nav-item`}
          alt={tabs.title}
        >
          <img
            className='side-nav-icon'
            src={tab.icon}
            alt={tab.link}
          ></img>

          <div className='side-nav-title'>{tab.title}</div>
        </div>
      ))}
    </div>
  );
};

export default PortalNavBar;
